import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Hamburger from "../components/Hamburger";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { authApi } from "../api/authServices";

const ROLE_EXECUTIVE = "Executive";

const Admin = () => {
  const [authContext, setAuthContext] = useAuth();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    if (
      authContext.userData &&
      authContext.userData.role.name !== ROLE_EXECUTIVE
    ) {
      authApi.logout();
      setAuthContext({ userData: null });
      setRedirectToLogin(true);
    }
  }, [authContext, setAuthContext]);

  return redirectToLogin ? (
    <Navigate to={`/auth/sign-in`} />
  ) : (
    <React.Fragment>
      <div className="d-flex min-width-lg h-100">
        <Sidebar />
        <div className="d-flex flex-column justify-content-between w-100 overflow-x-hidden">
          <Hamburger />
          <div className="p-2 flex-grow-1 d-flex flex-column">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admin;
