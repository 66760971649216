import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Button,
} from "reactstrap";

import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";

import { useGetWorkPerformedByProject } from "../../api/reports/Reports.hooks";
import moment from "moment";
import Loader from "../../components/Loader";
import { sharedHelper } from "../../helpers/sharedHelper";

import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetProjects,
  useGetSprints,
  useGetStatuses,
} from "../../api/projects/Projects.hooks";
import DatesSelector from "../../components/DatesSelector";
import { useAuth } from "../../providers/authProvider";

const DOWNLOAD = "DOWNLOAD";
const ROLE_EXECUTIVE = "Executive";
const ROLE_CUSTOMER = "Customer";
const ROLE_ENGINEER = "Engineer";

const WorkPerformed = ({ project, data }) => {
  const [authContext] = useAuth();

  const columns = data.columns
    .filter((col) => col.header)
    .map((col) => ({
      accessor: col.key,
      Header: col.header,
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 200,
      Cell: (rowData) => {
        const data = rowData.row.original[col.key];
        if (!data) {
          return "-";
        }
        const isLastRow = rowData.data.length === rowData.row.index + 1;
        if (data.hyperlink) {
          return (
            <a
              className="text-link"
              target="_blank"
              rel="noreferrer"
              href={data.hyperlink}
            >
              {data.text}
            </a>
          );
        }
        if (col.header.indexOf("Hs") > -1) {
          return (
            <span className={isLastRow ? "font-weight-bold" : ""}>
              {sharedHelper.formatDecimal(data, 2)}
            </span>
          );
        }
        if (col.header.indexOf("$") > -1) {
          return (
            <span className={isLastRow ? "font-weight-bold" : ""}>
              {sharedHelper.formatCurrency(data, 2)}
            </span>
          );
        }
        return (
          <span className={isLastRow ? "font-weight-bold" : ""}>{data}</span>
        );
      },
    }));

  return (
    <Col className="flex-grow-1 d-flex px-0 flex-column">
      <div className="overflow-x-auto bg-white">
        {authContext.userData.role.name !== ROLE_CUSTOMER ? (
          <h4 className="text-center mb-3">Flat Totals</h4>
        ) : null}
        <AdvanceTableWrapper
          exportable
          exportName={`${project.name}.csv`}
          columns={columns}
          data={data.rows}
          pageSize={data.rows.length}
        >
          <div className="w-100">
            <AdvanceTable
              table
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0 overflow-hidden",
              }}
            />
          </div>
        </AdvanceTableWrapper>
      </div>
      {authContext.userData.role.name === ROLE_EXECUTIVE ? (
        <>
          <h4 className="text-center my-3">Overage Totals</h4>
          <div className="d-flex flex-wrap">
            {Object.keys(data.overages).map((role) => (
              <div className="col-4" key={role.id}>
                <Card>
                  <CardBody>
                    <div className="bg-lighter border-radius-md py-1 px-2 text-center font-weight-bold">
                      {role}
                    </div>
                    {data.overages[role].map((entry) => {
                      const key = Object.keys(entry)[0];
                      const value = Object.values(entry)[0];
                      return (
                        <div
                          key={key}
                          className="p-2 d-flex align-items-center justify-content-between border-bottom"
                        >
                          <span>{key}</span>
                          <span>
                            {key.indexOf("Invoice") > -1 ||
                            key.indexOf("Margin") > -1
                              ? sharedHelper.formatCurrency(value, 2)
                              : sharedHelper.formatDecimal(value, 2)}
                          </span>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </Col>
  );
};

const WorkPerformedByProject = () => {
  const [authContext] = useAuth();
  const [dates, setDates] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState();

  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState();

  const [sprints, setSprints] = useState();
  const [sprint, setSprint] = useState();

  const [activeTab, setActiveTab] = useState();
  const [workPerformedByProject, setWorkPerformedByProject] = useState({});
  const [refresh, setRefresh] = useState();

  const {
    data: workPerformedData = {},
    isLoading: isLoadingWorkPerformedByProject,
    get: getWorkPerformedByProject,
  } = useGetWorkPerformedByProject();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    get: getProjects,
  } = useGetProjects();

  const {
    data: sprintsData,
    isLoading: isLoadingSprints,
    get: getSprints,
  } = useGetSprints(project?.id);

  const {
    data: statusesData,
    isLoading: isLoadingStatuses,
    get: getStatuses,
  } = useGetStatuses(project?.id);

  useEffect(() => {
    if (!isLoadingProjects && sprints) {
      const params = {
        projectId: project?.id,
        status,
      };
      if (sprint) {
        params.sprintId = sprint;
      } else {
        params.startDate = dates.startDate;
        params.endDate = dates.endDate;
      }
      getWorkPerformedByProject(params);
    }
  }, [
    getWorkPerformedByProject,
    sprints,
    status,
    refresh,
    project,
    isLoadingProjects,
    dates,
    sprint,
  ]);

  useEffect(() => {
    if (workPerformedData) {
      if (
        workPerformedData.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const blob = new Blob([workPerformedData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "download.xlsx";
        a.click();
      } else {
        if (
          authContext.userData?.role?.name === ROLE_ENGINEER &&
          !projects.length
        ) {
          setProjects(workPerformedData.projects);
        }
        const projectId = Object.keys(workPerformedData.data).find(
          (projectId) => workPerformedData.data[projectId].rows.length > 1
        );
        setActiveTab(parseInt(projectId));
        setWorkPerformedByProject(workPerformedData.data);
      }
    }
  }, [
    projects.length,
    workPerformedData,
    setWorkPerformedByProject,
    workPerformedByProject,
    authContext.userData,
    project,
  ]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onDownload = async () => {
    const params = {
      projectId: project?.id,
      status,
      mode: DOWNLOAD,
    };
    if (sprint) {
      params.sprintId = sprint;
    } else {
      params.startDate = dates.startDate;
      params.endDate = dates.endDate;
    }
    if (authContext.userData?.role?.name === ROLE_ENGINEER) {
      params.employeeId = authContext.userData.employeeId;
    }
    getWorkPerformedByProject(params, "blob");
  };

  useEffect(() => {
    if (authContext.userData?.role?.name !== ROLE_ENGINEER) {
      getProjects({});
    }
  }, [getProjects, authContext.userData]);

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData);
    }
  }, [statusesData, setStatuses]);

  useEffect(() => {
    if (project) {
      getSprints();
      getStatuses();
    }
  }, [getSprints, getStatuses, project]);

  useEffect(() => {
    if (projectsData) {
      const project = projectsData.data[0];
      setProject(project);
      setProjects(projectsData.data);
    }
  }, [projectsData, setProjects, authContext.userData]);

  useEffect(() => {
    if (sprintsData) {
      setSprints(sprintsData);
      const activeSprint = sprintsData.find((s) => s.state === "active");
      if (activeSprint) {
        setSprint(activeSprint.id);
      }
    }
  }, [sprintsData, setSprints, setSprint]);

  const isLoading =
    isLoadingWorkPerformedByProject ||
    isLoadingProjects ||
    isLoadingSprints ||
    isLoadingStatuses;

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Col>
        <Card className="box-shadow-none">
          <CardBody className="d-flex">
            <div className="text-dark flex-grow-1 d-flex flex-column align-items-start">
              <h2 className="mb-0">Work by Project</h2>
              {!isLoading ? (
                <small className="text-muted">
                  {Object.keys(workPerformedByProject)
                    .reduce(
                      (p, c) =>
                        p + [...workPerformedByProject[c].rows].pop().totalHs,
                      0
                    )
                    .toFixed(2)}{" "}
                  total hours
                </small>
              ) : null}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {!isLoadingProjects ? (
                <InputGroup className="ml-3">
                  <select
                    disabled={isLoading}
                    className="form-control-redesign"
                    type="select"
                    name="projectSelect"
                    id="projectSelect"
                    onChange={(evt) => {
                      const project = projects.find(
                        (p) => p.id === parseInt(evt.currentTarget.value)
                      );
                      setProject(project);
                      setSprint();
                      setSprints([]);
                    }}
                    value={project?.id || ""}
                  >
                    <option value={""}>All Projects</option>
                    {projects.map((project) => (
                      <option value={project.id} key={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
              ) : null}
              {!isLoadingSprints && project ? (
                <InputGroup className="ml-3">
                  <select
                    disabled={isLoading}
                    className="form-control-redesign"
                    type="select"
                    name="sprintSelect"
                    id="sprintSelect"
                    onChange={(evt) => setSprint(evt.currentTarget.value)}
                    value={sprint}
                  >
                    <option value={""}>By Date</option>
                    {sprints?.map((sprint) => (
                      <option
                        key={sprint.id}
                        value={sprint.id}
                      >{`${sprint.name} (${sprint.state})`}</option>
                    ))}
                  </select>
                </InputGroup>
              ) : null}
              {!isLoadingStatuses && project ? (
                <InputGroup className="ml-3">
                  <select
                    disabled={isLoading}
                    className="form-control-redesign"
                    type="select"
                    name="sprintSelect"
                    id="sprintSelect"
                    onChange={(evt) => setStatus(evt.currentTarget.value)}
                    value={status || ""}
                  >
                    <option value={""}>Any status</option>
                    {statuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </InputGroup>
              ) : null}
              {!sprint ? (
                <div className="ml-3">
                  <DatesSelector
                    disabled={isLoading}
                    defaultStartDate={dates.startDate}
                    defaultEndDate={dates.endDate}
                    onSubmit={(startDate, endDate) => {
                      setDates({
                        startDate: sharedHelper.formatDate(startDate),
                        endDate: sharedHelper.formatDate(endDate),
                      });
                    }}
                  />
                </div>
              ) : null}
              <Button
                disabled={isLoading}
                size="sm"
                className="ml-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh(!refresh)}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              {authContext.userData?.role?.name !== ROLE_ENGINEER ? (
                <Button
                  disabled={isLoading}
                  size="sm"
                  className="ml-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  onClick={onDownload}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              ) : null}
              <div id="table-export" />
            </div>
          </CardBody>
        </Card>
        {isLoading ? (
          <Loader />
        ) : (
          <Col className="flex-grow-1 d-flex flex-column">
            <div className="tab h-100">
              <Nav
                tabs
                className="cursor-pointer overflow-x-auto overflow-y-hidden flex-nowrap"
              >
                {Object.keys(workPerformedByProject)
                  .filter((projectId) =>
                    authContext.userData?.role?.name === ROLE_ENGINEER &&
                    !project
                      ? workPerformedByProject[projectId].rows.length > 1
                      : true
                  )
                  .map((projectId) => {
                    const project = projects.find(
                      (p) => p.id === parseInt(projectId)
                    );
                    return project ? (
                      <NavItem className="flex-shrink-0" key={project.id}>
                        <NavLink
                          className={`${
                            activeTab === project.id
                              ? "active font-weight-bold"
                              : ""
                          } d-flex align-items-center`}
                          onClick={() => toggle(project.id)}
                        >
                          <div className="d-flex align-items-center">
                            <img
                              alt={project.name}
                              className="img rounded-circle"
                              src={project.avatar}
                              style={{ maxWidth: 24 }}
                            />
                            <span className="ml-2">{project.name}</span>
                          </div>
                          {!isLoading ? (
                            <small className="ml-1 text-muted">
                              (
                              {[...workPerformedByProject[projectId].rows]
                                .pop()
                                .totalHs.toFixed(2)}{" "}
                              hrs)
                            </small>
                          ) : null}
                        </NavLink>
                      </NavItem>
                    ) : null;
                  })}
              </Nav>
              <TabContent
                activeTab={activeTab}
                className="h-100 box-shadow-none bg-white"
              >
                {Object.keys(workPerformedByProject)
                  .filter((projectId) =>
                    authContext.userData?.role?.name === ROLE_ENGINEER &&
                    !project
                      ? workPerformedByProject[projectId].rows.length > 1
                      : true
                  )
                  .map((projectId) => {
                    const project = projects.find(
                      (p) => p.id === parseInt(projectId)
                    );
                    return project ? (
                      <TabPane tabId={project.id} key={project.id}>
                        {activeTab === parseInt(project.id) ? (
                          <WorkPerformed
                            project={project}
                            data={workPerformedByProject[project.id]}
                          />
                        ) : null}
                      </TabPane>
                    ) : null;
                  })}
              </TabContent>
            </div>
          </Col>
        )}
      </Col>
    </Container>
  );
};

export default WorkPerformedByProject;
