import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  UncontrolledAlert,
} from "reactstrap";

import brand from "../../../assets/img/logo-br-blue-vert.png";

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../providers/authProvider";
import { authApi } from "../../../api/authServices";
import Loader from "../../../components/Loader";
import { sharedHelper } from "../../../helpers/sharedHelper";

export const LoginForm = () => {
  const [, setAuthContext] = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onDismiss = () => setError(false);

  const doLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await authApi.login({
        email,
        password,
      });
      setLoading(false);
      if (!result) {
        return null;
      }
      setAuthContext({ userData: result });
    } catch (err) {
      setLoading(false);
      sharedHelper.errorToast(err);
    }
    return false;
  };

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <Card className="login-box mx-auto bg-white">
        <CardBody className="mx-auto d-flex flex-column">
          <div className="text-center">
            <img
              src={brand}
              className="text-primary col-12 my-4"
              alt={"Beloved Robot"}
            />
          </div>
          <Form
            onSubmit={doLogin}
            className="mx-auto form-container flex-grow-1 d-flex flex-column mt-5"
          >
            <FormGroup className="d-flex flex-column">
              <Label className="font-weight-bold">Email</Label>
              <input
                className="mb-2 login-input"
                id="emailUser"
                data-test-id="email-input"
                maxLength="50"
                required={true}
                name="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value.trim())}
              />
            </FormGroup>
            <FormGroup className="d-flex flex-column">
              <Label className="font-weight-bold">Password</Label>
              <input
                className="login-input"
                id="passwordUser"
                required={true}
                maxLength="50"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
            </FormGroup>
            <div className="text-center mt-3 flex-grow-1 d-flex flex-column justify-content-center">
              <UncontrolledAlert
                isOpen={!!error}
                toggle={onDismiss}
                color="warning"
              >
                <div className="alert-icon">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="alert-message text-left">
                  <span className="ml-2">{error}</span>
                </div>
              </UncontrolledAlert>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <Button
                  className="w-100"
                  id="signIn"
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  LOGIN
                </Button>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};
