import React, { useEffect } from "react";

import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
} from "react-table";

import { CSVLink } from "react-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  pageSize,
  sortable,
  exportable,
  exportName = "data.csv",
  onSort,
  defaultSort,
}) => {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    setPageSize,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      disableSortBy: !sortable,
      manualSortBy: true,
      initialState: {
        sortBy: defaultSort
          ? [
              {
                id: defaultSort.sortBy,
                desc: defaultSort.direction === "desc",
              },
            ]
          : [],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [sortBy, onSort]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        });
      } else {
        if (child?.props?.table) {
          return (
            <div className="d-flex flex-column">
              {React.cloneElement(child, {
                ...child.props,
                getTableProps,
                headers,
                page,
                prepareRow,
              })}
            </div>
          );
        } else {
          if (child?.props?.id === "table-export" && exportable) {
            const exportHeaders = columns
              .map((column) => ({
                label: column.Header,
                key: column.accessor,
                formatter: column.Cell,
              }))
              .filter((header) => header.label.length);
            const exportData = data.map((entry) => ({
              ...exportHeaders.reduce((p, c) => {
                p[c.key] = c.formatter({
                  row: { original: entry, isExport: true },
                });
                return p;
              }, {}),
            }));
            return (
              <CSVLink
                data={exportData}
                headers={exportHeaders}
                filename={exportName}
                className="btn btn-white text-primary btn-sm rounded-circle d-flex custom-rounded-button py-2 mr-3"
              >
                <FontAwesomeIcon icon={faDownload} />
              </CSVLink>
            );
          }
          return child;
        }
      }
    });
  };

  return recursiveMap(children);
};

export default AdvanceTableWrapper;
