import { useCallback } from "react";
import { useApiGet, useApiMutation } from "../Api.hooks";
import { HttpMethod } from "../Api";

const endpoint = `/api/projects`;

export const useGetProjects = () => {
  return useApiGet(endpoint);
};

export const useGetSprints = (projectId) => {
  return useApiGet(`${endpoint}/${projectId}/sprints`);
};

export const useGetStatuses = (projectId) => {
  return useApiGet(`${endpoint}/${projectId}/statuses`);
};

export const useSyncProjects = () => {
  return useApiGet(`${endpoint}/sync-with-jira`);
};

export const useCreateProject = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateProject = (projectId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${projectId}`
  );

  const update = useCallback(
    async (project) => {
      await mutate(project);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteProject = (projectId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${projectId}`
  );

  const deleteProject = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteProject };
};
