import axios from "axios";
import { sharedHelper } from "../helpers/sharedHelper";

export const HttpMethod = {
  GET: "get",
  PATCH: "patch",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const apiHelper = async (method, path, params = {}, responseType) => {
  let requestOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    method: method,
    responseType,
  };
  if (method === HttpMethod.GET || method === HttpMethod.DELETE) {
    const query = sharedHelper.buildQueryString(params);
    path = `${path}?${query}`;
  } else if (
    method === HttpMethod.PATCH ||
    method === HttpMethod.POST ||
    method === HttpMethod.PUT
  ) {
    requestOptions = {
      ...requestOptions,
      data: params,
    };
  }
  const response = await axios(path, requestOptions);
  return response;
};

export const Api = {
  async get(path, params, responseType) {
    return apiHelper(HttpMethod.GET, path, params, responseType);
  },
  async post(path, params) {
    return apiHelper(HttpMethod.POST, path, params);
  },
  async put(path, params) {
    return apiHelper(HttpMethod.PUT, path, params);
  },
  async delete(path, params) {
    return apiHelper(HttpMethod.DELETE, path, params);
  },
};
