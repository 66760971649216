import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Button,
} from "reactstrap";

import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";

import { useGetWorkPerformedByEmployee } from "../../api/reports/Reports.hooks";
import moment from "moment";
import Loader from "../../components/Loader";
import { sharedHelper } from "../../helpers/sharedHelper";

import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetProjects,
  useGetSprints,
  useGetStatuses,
} from "../../api/projects/Projects.hooks";
import DatesSelector from "../../components/DatesSelector";
import { useGetEmployees } from "../../api/employees/Employees.hooks";

const DOWNLOAD = "DOWNLOAD";

const WorkPerformed = ({ employee, data }) => {
  const columns = data.columns.map((col) => ({
    accessor: col.key,
    Header: col.header,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 200,
    Cell: (rowData) => {
      const data = rowData.row.original[col.key];
      if (!data) {
        return "-";
      }
      const isLastRow = rowData.data.length === rowData.row.index + 1;
      if (data.hyperlink) {
        return (
          <a
            className="text-link"
            target="_blank"
            rel="noreferrer"
            href={data.hyperlink}
          >
            {data.text}
          </a>
        );
      }
      if (col.header.indexOf("Hs") > -1) {
        return (
          <span className={isLastRow ? "font-weight-bold" : ""}>
            {sharedHelper.formatDecimal(data, 2)}
          </span>
        );
      }
      if (col.header.indexOf("$") > -1) {
        return (
          <span className={isLastRow ? "font-weight-bold" : ""}>
            {sharedHelper.formatCurrency(data, 2)}
          </span>
        );
      }
      return (
        <span className={isLastRow ? "font-weight-bold" : ""}>{data}</span>
      );
    },
  }));
  return (
    <Col className="flex-grow-1 d-flex px-0 flex-column">
      <div className="overflow-x-auto bg-white">
        <h4 className="text-center mb-3">Flat Totals</h4>
        <AdvanceTableWrapper
          exportable
          exportName={`${employee.name}.csv`}
          columns={columns}
          data={data.rows}
          pageSize={data.rows.length}
        >
          <div className="w-100">
            <AdvanceTable
              table
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0 overflow-hidden",
              }}
            />
          </div>
        </AdvanceTableWrapper>
      </div>
    </Col>
  );
};

const WorkPerformedByEmployee = () => {
  const [dates, setDates] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const [employee, setEmployee] = useState();
  const [employees, setEmployees] = useState([]);

  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState();

  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState();

  const [sprints, setSprints] = useState();
  const [sprint, setSprint] = useState();

  const [activeTab, setActiveTab] = useState();
  const [workPerformedByEmployee, setWorkPerformedByEmployee] = useState({});
  const [refresh, setRefresh] = useState();

  const {
    data: workPerformedData = {},
    isLoading: isLoadingWorkPerformedByEmployee,
    get: getWorkPerformedByEmployee,
  } = useGetWorkPerformedByEmployee();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    get: getProjects,
  } = useGetProjects();

  useEffect(() => {
    if (!isLoadingProjects && sprints) {
      const params = {
        projectId: project?.id,
        employeeId: employee?.id,
        status,
      };
      if (sprint) {
        params.sprintId = sprint;
      } else {
        params.startDate = dates.startDate;
        params.endDate = dates.endDate;
      }
      getWorkPerformedByEmployee(params);
    }
  }, [
    getWorkPerformedByEmployee,
    isLoadingProjects,
    refresh,
    employee,
    project,
    projects,
    dates,
    sprint,
    sprints,
    status,
  ]);

  useEffect(() => {
    if (workPerformedData) {
      if (
        workPerformedData.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const blob = new Blob([workPerformedData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "download.xlsx";
        a.click();
      } else {
        setActiveTab(Object.keys(workPerformedByEmployee)[0]);
        setWorkPerformedByEmployee(workPerformedData);
      }
    }
  }, [workPerformedData, setWorkPerformedByEmployee, workPerformedByEmployee]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onDownload = async () => {
    const params = {
      projectId: project?.id,
      employeeId: employee?.id,
      status,
      mode: DOWNLOAD,
    };
    if (sprint) {
      params.sprintId = sprint;
    } else {
      params.startDate = dates.startDate;
      params.endDate = dates.endDate;
    }
    getWorkPerformedByEmployee(params, "blob");
  };

  const {
    data: employeesData,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const {
    data: sprintsData,
    isLoading: isLoadingSprints,
    get: getSprints,
  } = useGetSprints(project?.id);

  const {
    data: statusesData,
    isLoading: isLoadingStatuses,
    get: getStatuses,
  } = useGetStatuses(project?.id);

  useEffect(() => {
    getProjects({});
    getEmployees({});
  }, [getProjects, getEmployees]);

  useEffect(() => {
    if (project) {
      getSprints();
      getStatuses();
    }
  }, [getSprints, getStatuses, project]);

  useEffect(() => {
    if (employeesData) {
      setEmployees(employeesData.data);
    }
  }, [employeesData, setEmployees]);

  useEffect(() => {
    if (projectsData) {
      const project = projectsData.data[0];
      setProject(project);
      setProjects(projectsData.data);
    }
  }, [projectsData, setProjects]);

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData);
    }
  }, [statusesData, setStatuses]);

  useEffect(() => {
    if (sprintsData) {
      setSprints(sprintsData);
      const activeSprint = sprintsData.find((s) => s.state === "active");
      if (activeSprint) {
        setSprint(activeSprint.id);
      }
    }
  }, [sprintsData, setSprints, setSprint]);

  const isLoading =
    isLoadingWorkPerformedByEmployee ||
    isLoadingProjects ||
    isLoadingSprints ||
    isLoadingStatuses ||
    isLoadingEmployees;

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Col>
        <Card className="box-shadow-none">
          <CardBody className="d-flex">
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0 ">Work by Employee</h2>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <InputGroup className="ml-3">
                <select
                  disabled={isLoading}
                  className="form-control-redesign"
                  type="select"
                  name="employeeSelect"
                  id="employeeSelect"
                  onChange={(evt) => {
                    const employee = employees.find(
                      (e) => e.id === evt.currentTarget.value
                    );
                    setEmployee(employee);
                  }}
                  value={employee?.id || ""}
                >
                  <option value={""}>All Employees</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </InputGroup>
              <InputGroup className="ml-3">
                <select
                  disabled={isLoading}
                  className="form-control-redesign"
                  type="select"
                  name="projectSelect"
                  id="projectSelect"
                  onChange={(evt) => {
                    const project = projects.find(
                      (p) => p.id === parseInt(evt.currentTarget.value)
                    );
                    setProject(project);
                    setSprints([]);
                    setSprint();
                  }}
                  value={project?.id || ""}
                >
                  <option value={""}>All Projects</option>
                  {projects.map((project) => (
                    <option value={project.id} key={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </InputGroup>
              {project ? (
                <InputGroup className="ml-3">
                  <select
                    disabled={isLoading}
                    className="form-control-redesign"
                    type="select"
                    name="sprintSelect"
                    id="sprintSelect"
                    onChange={(evt) => setSprint(evt.currentTarget.value)}
                    value={sprint}
                  >
                    <option value={""}>By Date</option>
                    {sprints?.map((sprint) => (
                      <option
                        key={sprint.id}
                        value={sprint.id}
                      >{`${sprint.name} (${sprint.state})`}</option>
                    ))}
                  </select>
                </InputGroup>
              ) : null}
              {project ? (
                <InputGroup className="ml-3">
                  <select
                    disabled={isLoading}
                    className="form-control-redesign"
                    type="select"
                    name="sprintSelect"
                    id="sprintSelect"
                    onChange={(evt) => setStatus(evt.currentTarget.value)}
                    value={status || ""}
                  >
                    <option value={""}>Any status</option>
                    {statuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </InputGroup>
              ) : null}
              {!sprint ? (
                <div className="ml-3">
                  <DatesSelector
                    disabled={isLoading}
                    defaultStartDate={dates.startDate}
                    defaultEndDate={dates.endDate}
                    onSubmit={(startDate, endDate) => {
                      setDates({
                        startDate: sharedHelper.formatDate(startDate),
                        endDate: sharedHelper.formatDate(endDate),
                      });
                    }}
                  />
                </div>
              ) : null}
              <Button
                disabled={isLoading}
                size="sm"
                className="ml-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh(!refresh)}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              <Button
                disabled={isLoading}
                size="sm"
                className="ml-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={onDownload}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <div id="table-export" />
            </div>
          </CardBody>
        </Card>
        {isLoading ? (
          <Loader />
        ) : (
          <Col className="flex-grow-1 d-flex flex-column">
            <div className="tab h-100">
              <Nav
                tabs
                className="cursor-pointer overflow-x-auto overflow-y-hidden flex-nowrap"
              >
                {Object.keys(workPerformedByEmployee).map((employeeId) => {
                  const employee = employees.find((p) => p.id === employeeId);
                  return (
                    <NavItem className="flex-shrink-0" key={employeeId}>
                      <NavLink
                        className={`${
                          activeTab === employeeId
                            ? "active font-weight-bold"
                            : ""
                        } d-flex align-items-center`}
                        onClick={() => toggle(employeeId)}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            alt={employee.name}
                            className="img rounded-circle"
                            src={employee.avatar}
                            style={{ maxWidth: 24 }}
                          />
                          <span className="ml-2">{employee.name}</span>
                        </div>
                        {!isLoading ? (
                          <small className="ml-1 text-muted">
                            (
                            {[...workPerformedByEmployee[employee.id].rows]
                              .pop()
                              .totalHs.toFixed(2)}{" "}
                            hrs)
                          </small>
                        ) : null}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
              <TabContent
                activeTab={activeTab}
                className="h-100 box-shadow-none bg-white"
              >
                {Object.keys(workPerformedByEmployee).map((employeeId) => {
                  const employee = employees.find((p) => p.id === employeeId);
                  return (
                    <TabPane tabId={employeeId} key={employeeId}>
                      {activeTab === employeeId ? (
                        <WorkPerformed
                          employee={employee}
                          data={workPerformedByEmployee[employeeId]}
                        />
                      ) : null}
                    </TabPane>
                  );
                })}
              </TabContent>
            </div>
          </Col>
        )}
      </Col>
    </Container>
  );
};

export default WorkPerformedByEmployee;
