import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Loader from "../Loader";
import { sharedHelper } from "../../helpers/sharedHelper";
import { useGetUserRoles } from "../../api/users/UserRoles.hooks";
import { useCreateUser, useUpdateUser } from "../../api/users/Users.hooks";
import SelectWrapper from "../SelectWrapper";
import { useGetProjects } from "../../api/projects/Projects.hooks";
import { useGetEmployees } from "../../api/employees/Employees.hooks";

const MAX_PAGE_SIZE = 999;

const ROLE_CUSTOMER = "Customer";
const ROLE_ENGINEER = "Engineer";

const UserModal = ({ user = {}, onClose, onSubmit }) => {
  const [userData, setUserData] = useState(user);
  const [employee, setEmployee] = useState();

  const {
    isLoading: isLoadingUpdateUser,
    update: updateUser,
    data: updateUserData,
  } = useUpdateUser(userData.id);

  const {
    isLoading: isLoadingCreateUser,
    mutate: createUser,
    data: createUserData,
  } = useCreateUser();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    get: getProjects,
  } = useGetProjects();

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const getProjectsSelect = async () => projectsData?.data || [];

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    get: getUserRoles,
  } = useGetUserRoles();

  useEffect(() => {
    if (updateUserData) {
      sharedHelper.successToast("User saved");
      onSubmit();
    }
  }, [updateUserData, onSubmit]);

  useEffect(() => {
    if (createUserData) {
      sharedHelper.successToast(`User created`);
      onSubmit();
    }
  }, [createUserData, onSubmit]);

  useEffect(() => {
    getUserRoles();
    getProjects();
    getEmployees();
  }, [getUserRoles, getProjects, getEmployees]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (userData.id) {
      await updateUser(userData);
    } else {
      await createUser(userData);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const customerRoleId = userRoles?.find(
    (userRole) => userRole.name === ROLE_CUSTOMER
  )?.id;

  const engineerRoleId = userRoles?.find(
    (userRole) => userRole.name === ROLE_ENGINEER
  )?.id;

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {userData.id ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingCreateUser || isLoadingUpdateUser ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label for="name">
                    <span>Name</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <input
                    disabled={
                      engineerRoleId &&
                      parseInt(userData.userRoleId) === engineerRoleId &&
                      employee?.name?.length
                    }
                    className="form-control-redesign"
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.name || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        name: e.target.value.trim(),
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="avatar">
                    <span>Avatar (URL)</span>
                  </Label>
                  <input
                    disabled={
                      engineerRoleId &&
                      parseInt(userData.userRoleId) === engineerRoleId &&
                      employee?.avatar?.length
                    }
                    className="form-control-redesign"
                    maxLength="200"
                    type="text"
                    name="avatar"
                    value={userData.avatar || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        avatar: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    <span>Email</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <input
                    disabled={
                      engineerRoleId &&
                      parseInt(userData.userRoleId) === engineerRoleId &&
                      employee?.email?.length
                    }
                    className="form-control-redesign"
                    maxLength="50"
                    type="email"
                    name="email"
                    value={userData.email || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    <span>Password</span>
                    {!userData.id ? (
                      <span className="text-danger ml-2">*</span>
                    ) : null}
                  </Label>
                  <input
                    className="form-control-redesign"
                    required={!userData.id}
                    autoComplete="new-password"
                    maxLength="50"
                    type="password"
                    name="password"
                    value={userData.password || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        password: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                {isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : (
                  <FormGroup>
                    <Label for="role">
                      <span>Role</span>
                      <span className="text-danger ml-2">*</span>
                    </Label>
                    <select
                      className="form-control-redesign"
                      type="select"
                      name="roleSelect"
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          userRoleId: e.target.value.trim(),
                        })
                      }
                      value={userData.userRoleId}
                      required
                    >
                      <option value={""}>Select a Role</option>
                      {userRoles?.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                )}
                {customerRoleId &&
                parseInt(userData.userRoleId) === customerRoleId &&
                projectsData ? (
                  isLoadingProjects ? (
                    <Loader size="sm" />
                  ) : (
                    <FormGroup>
                      <Label for="projects">
                        <span>Projects</span>
                        <span className="text-danger ml-2">*</span>
                      </Label>
                      <SelectWrapper
                        className="form-control-redesign"
                        isMulti={true}
                        entity="project"
                        filterItemFunction={(project) =>
                          !userData.projects ||
                          !userData.projects.find((p) => p.id === project.id)
                        }
                        formatItemFunction={(item) => ({
                          value: item.id,
                          label: item.name,
                        })}
                        fetchFunction={getProjectsSelect}
                        fetchParameters={{
                          pageSize: MAX_PAGE_SIZE,
                        }}
                        defaultSelected={userData.projects}
                        onSelected={(projects) =>
                          setUserData({
                            ...userData,
                            projects: [
                              ...projects.map((p) => ({
                                id: p.id,
                              })),
                            ],
                          })
                        }
                      />
                    </FormGroup>
                  )
                ) : null}
                {employees?.data ? (
                  isLoadingEmployees ? (
                    <Loader size="sm" />
                  ) : (
                    <FormGroup>
                      <Label for="employes">
                        <span>Employee</span>
                        {parseInt(userData.userRoleId) === engineerRoleId ? (
                          <span className="text-danger ml-2">*</span>
                        ) : null}
                      </Label>
                      <select
                        className="form-control-redesign"
                        type="select"
                        name="employeeSelect"
                        onChange={(e) => {
                          const employee = employees.data.find(
                            (em) => em.id === e.target.value
                          );
                          setEmployee(employee);
                          const { name, email, avatar } = employee;
                          setUserData({
                            ...userData,
                            employeeId: e.target.value,
                            name,
                            email,
                            avatar,
                          });
                        }}
                        value={userData.employeeId}
                        required={
                          parseInt(userData.userRoleId) === engineerRoleId
                        }
                      >
                        <option value={""}>Select an Employee</option>
                        {employees.data?.map((employee) => (
                          <option key={employee.id} value={employee.id}>
                            {employee.name}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  )
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color="secondary" onClick={onClose} className="text-dark">
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
