import { useCallback } from "react";
import { useApiGet, useApiMutation } from "../Api.hooks";
import { HttpMethod } from "../Api";

const endpoint = `/api/user-roles`;

export const useGetUserRoles = (data) => {
  return useApiGet(data?.id ? `${endpoint}/${data.id}` : endpoint);
};

export const useCreateUserRole = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateUserRole = (RoleId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${RoleId}`
  );

  const update = useCallback(
    async (Role) => {
      await mutate(Role);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteUserRole = (RoleId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${RoleId}`
  );

  const deleteUserRole = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteUserRole };
};
