import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  useUpdateEmployee,
  useCreateEmployee,
} from "../../api/employees/Employees.hooks";

import { useGetEmployeeRoles } from "../../api/employees/EmployeeRoles.hooks";
import { sharedHelper } from "../../helpers/sharedHelper";
import Loader from "../Loader";
import CustomCheckbox from "../CustomCheckbox";

const EmployeeModal = ({
  employee = { isActive: true },
  onSubmit,
  onClose,
}) => {
  const [employeeData, setEmployeeData] = useState(employee);

  const {
    data: employeeRoles,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  useEffect(() => {
    getEmployeeRoles({});
  }, [getEmployeeRoles]);

  const {
    isLoading: isUpdatingEmployee,
    update: updateEmployee,
    data: updateEmployeeData,
  } = useUpdateEmployee(employee.id);

  const {
    isLoading: isLoadingCreateEmployee,
    mutate: createEmployee,
    data: createEmployeeData,
  } = useCreateEmployee();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (employee.id) {
      await updateEmployee({
        ...employeeData,
      });
    } else {
      await createEmployee({
        ...employeeData,
      });
    }
  };

  useEffect(() => {
    if (updateEmployeeData) {
      sharedHelper.successToast(`Employee saved`);
      onSubmit();
    }
  }, [updateEmployeeData, onSubmit]);

  useEffect(() => {
    if (createEmployeeData) {
      sharedHelper.successToast(`Employee created`);
      onSubmit();
    }
  }, [createEmployeeData, onSubmit]);

  useEffect(() => {
    if (updateEmployeeData) {
      sharedHelper.successToast(`Employee saved`);
      onSubmit();
    }
  }, [updateEmployeeData, onSubmit]);

  useEffect(() => {
    if (createEmployeeData) {
      sharedHelper.successToast(`Employee created`);
      onSubmit();
    }
  }, [createEmployeeData, onSubmit]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="sm">
      <ModalHeader className="d-flex justify-content-between" close={closeBtn}>
        {employee.id ? "Edit" : "Create"} Employee
      </ModalHeader>
      <ModalBody>
        <Row>
          {isUpdatingEmployee || isLoadingCreateEmployee ? (
            <Loader size="sm" />
          ) : (
            <Col sm="12">
              <FormGroup>
                <Label for="name">
                  <span>Name</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="name"
                  placeholder="Enter the name.."
                  value={employeeData.name || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      name: e.target.value.trim(),
                    })
                  }
                  required
                />
              </FormGroup>
              {isLoadingEmployeeRoles ? (
                <Loader size="sm" />
              ) : (
                <FormGroup>
                  <Label for="role">
                    <span>Role</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <select
                    className="form-control-redesign"
                    type="select"
                    name="roleSelect"
                    id="roleSelect"
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        employeeRoleId: e.target.value,
                      })
                    }
                    value={employeeData.employeeRoleId}
                    required
                  >
                    <option value={""}>Select a role..</option>
                    {employeeRoles?.data.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="email">
                  <span>Cost per Hour</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="number"
                  name="cost"
                  placeholder="Enter the cost.."
                  value={employeeData.cost || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      cost: e.target.value.trim(),
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">
                  <span>Email</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="email"
                  placeholder="Enter the email.."
                  value={employeeData.email || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      email: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">
                  <span>Phone</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="phone"
                  placeholder="Enter the phone.."
                  value={employeeData.phone || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      phone: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="country">
                  <span>Country</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="country"
                  placeholder="Enter the country.."
                  value={employeeData.country || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      country: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="city">
                  <span>City</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="city"
                  placeholder="Enter the city.."
                  value={employeeData.city || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      city: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="address">
                  <span>Address</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="address"
                  placeholder="Enter the address.."
                  value={employeeData.address || ""}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      address: e.target.value.trim(),
                    })
                  }
                />
              </FormGroup>
              {employee.id ? (
                <ListGroup className="mt-4">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer border-0"
                    onClick={() =>
                      setEmployeeData({
                        ...employeeData,
                        isActive: !employeeData.isActive,
                      })
                    }
                  >
                    <div className="flex-shrink-0">Active</div>
                    <div className="min-width-50 d-flex justify-content-end">
                      <CustomCheckbox checked={employeeData.isActive} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              ) : null}
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color="secondary" onClick={onClose} className="text-dark">
              Cancel
            </Button>
            <Button color="primary" onClick={doSubmit}>
              Save
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeModal;
