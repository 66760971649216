import React, { useState } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

import moment from "moment";

import DateRangePicker from "./dateRange/DateRangePicker";

const EditModal = ({
  isOpen,
  setIsOpen,
  startDate,
  endDate,
  onSelection,
  onSubmit,
}) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={() => setIsOpen(false)}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={isOpen} onClosed={() => setIsOpen(false)}>
      <ModalHeader close={closeBtn}>Date Range Selector</ModalHeader>
      <ModalBody>
        <DateRangePicker
          label="Selected Dates"
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          onSelection={onSelection}
        />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color="secondary"
          onClick={() => setIsOpen(false)}
          className="text-dark"
        >
          Discard
        </Button>{" "}
        <div className="min-width-50">
          <Button
            disabled={!startDate || !endDate}
            color="primary"
            onClick={() => {
              setIsOpen(false);
              onSubmit(startDate, endDate);
            }}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const DatesSelector = ({
  disabled,
  defaultStartDate,
  defaultEndDate,
  onSubmit,
  modalParent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const Data = () => {
    return (
      <div className="d-flex cursor-pointer align-items-center">
        <div
          onClick={() => (disabled ? null : setIsOpen(true))}
          className="form-control-redesign d-flex align-items-center justify-content-between"
        >
          {moment(defaultStartDate).format("MM/DD/YYYY")}
        </div>
        <small className="mx-2">-</small>
        <div
          onClick={() => (disabled ? null : setIsOpen(true))}
          className="form-control-redesign d-flex align-items-center justify-content-between"
        >
          {moment(defaultEndDate).format("MM/DD/YYYY")}
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column justify-content-center w-100">
      {modalParent ? (
        isOpen ? (
          <div className="d-flex flex-column">
            <DateRangePicker
              label="Selected Dates"
              startDate={moment(startDate)}
              endDate={moment(endDate)}
              onSelection={onSelection}
              onDiscard={() => setIsOpen(false)}
              onSave={() => {
                setIsOpen(false);
                onSubmit(startDate, endDate);
              }}
            />
          </div>
        ) : (
          <Data />
        )
      ) : (
        <>
          <Data />
          <EditModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            startDate={startDate}
            endDate={endDate}
            onSelection={onSelection}
            onSubmit={onSubmit}
          />
        </>
      )}
    </div>
  );
};

export default DatesSelector;
