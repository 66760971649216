import React, { useEffect } from "react";

import {
  faArchive,
  faCogs,
  faSignOutAlt,
  faUserGroup,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import brand from "../../assets/img/logo-sm.png";

import { useNavigate } from "react-router-dom";

import {
  useSidebar,
  ACTIONS as SIDEBAR_ACTIONS,
} from "../../providers/sidebarProvider";

import { useAuth } from "../../providers/authProvider";
import { authApi } from "../../api/authServices";
import SidebarCategory from "./SidebarCategory";
import WorkPerformedByProject from "../../pages/reports/WorkPerformedByProject";
import WorkPerformedByEmployee from "../../pages/reports/WorkPerformedByEmployee";

const ROLE_EXECUTIVE = "Executive";
const ROLE_MANAGER = "Manager";
const ROLE_CUSTOMER = "Customer";
const ROLE_ENGINEER = "Engineer";

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  const logout = () => {
    authApi.logout();
    setAuthContext({ userData: null });
    navigate("/auth/sign-in");
  };

  const routes = [
    {
      icon: faCogs,
      name: "Projects",
      sidebar: true,
      path: "projects",
      roles: [ROLE_EXECUTIVE],
    },
    {
      name: "Reports",
      icon: faArchive,
      sidebar: true,
      path: "reports",
      entries: [
        {
          path: `work-performed-by-project`,
          name: "Work by Project",
          sidebar: true,
          component: WorkPerformedByProject,
          roles: [ROLE_EXECUTIVE, ROLE_MANAGER, ROLE_CUSTOMER, ROLE_ENGINEER],
        },
        {
          path: `work-performed-by-employee`,
          name: "Work by Employee",
          sidebar: true,
          component: WorkPerformedByEmployee,
          roles: [ROLE_EXECUTIVE, ROLE_MANAGER],
        },
      ],
    },
    {
      icon: faUserGroup,
      name: "Employees",
      sidebar: true,
      path: "employees",
      roles: [ROLE_EXECUTIVE],
    },
    {
      icon: faUserGroup,
      name: "Employee Roles",
      sidebar: true,
      path: "employee-roles",
      roles: [ROLE_EXECUTIVE],
    },
    {
      icon: faUsers,
      name: "Users",
      sidebar: true,
      path: "users",
      roles: [ROLE_EXECUTIVE],
    },
  ];

  useEffect(() => {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.RESET_ROUTES,
    });
  }, [authContext.userData?.packages, setSidebarContext]);

  const isCustomer = authContext.userData?.role?.name === "Customer";

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? "toggled" : ""}`}>
      <div className="sidebar-content py-1">
        <div className="pt-3 pb-1 mb-2 sidebar-brand d-flex flex-column align-items-center justify-content-center px-0">
          <img
            src={brand}
            className="rounded p-2 col-12"
            alt={"Beloved Robot"}
          />
          <div className="mb-1 d-flex flex-column align-items-center">
            <h4 className="mb-0">Beloved Robot</h4>
            <span className="text-muted">
              {isCustomer ? "Customers" : "Admin"}
            </span>
          </div>
        </div>
        <SideBarUser />
        <div className="sidebar-nav d-flex flex-column p-4 justify-content-between">
          <div className="d-flex flex-column">
            {routes
              .filter((route) =>
                route.roles
                  ? route.roles.indexOf(authContext.userData?.role.name) > -1
                  : true
              )
              .map((route, index) =>
                route.entries ? (
                  <SidebarCategory
                    key={index}
                    name={route.name}
                    icon={route.icon}
                    path={route.path}
                    entries={route.entries.filter((route) =>
                      route.roles
                        ? route.roles.indexOf(authContext.userData?.role.name) >
                          -1
                        : true
                    )}
                  />
                ) : (
                  <SidebarItem
                    className="mb-1"
                    key={index}
                    name={route.name}
                    icon={route.icon}
                    path={route.path}
                  />
                )
              )}
            <SidebarItem name="Logout" icon={faSignOutAlt} action={logout} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
