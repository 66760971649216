import { useCallback } from "react";
import { useApiGet, useApiMutation } from "../Api.hooks";
import { HttpMethod } from "../Api";

const endpoint = `/api/employee-roles`;

export const useGetEmployeeRoles = (data) => {
  return useApiGet(data?.id ? `${endpoint}/${data.id}` : endpoint);
};

export const useCreateEmployeeRole = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateEmployeeRole = (RoleId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${RoleId}`
  );

  const update = useCallback(
    async (Role) => {
      await mutate(Role);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteEmployeeRole = (RoleId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${RoleId}`
  );

  const deleteEmployeeRole = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteEmployeeRole };
};
