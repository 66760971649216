import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  ListGroupItem,
} from "reactstrap";

import {
  useCreateEmployeeRole,
  useUpdateEmployeeRole,
} from "../../api/employees/EmployeeRoles.hooks";

import { sharedHelper } from "../../helpers/sharedHelper";
import CustomCheckbox from "../CustomCheckbox";
import Loader from "../Loader";

const EmployeeRoleModal = ({ employeeRole = {}, onClose, onSubmit }) => {
  const [employeeRoleData, setEmployeeRoleData] = useState(employeeRole);

  const {
    isLoading: isLoadingUpdateEmployeeRole,
    update: updateEmployeeRole,
    data: updateEmployeeRoleData,
  } = useUpdateEmployeeRole(employeeRole?.id);

  const {
    isLoading: isLoadingCreateEmployeeRole,
    mutate: createEmployeeRole,
    data: createEmployeeRoleData,
  } = useCreateEmployeeRole();

  useEffect(() => {
    if (updateEmployeeRoleData) {
      sharedHelper.successToast(`Employee role saved`);
      onSubmit();
    }
  }, [updateEmployeeRoleData, onSubmit]);

  useEffect(() => {
    if (createEmployeeRoleData) {
      sharedHelper.successToast(`Employee role created`);
      onSubmit();
    }
  }, [createEmployeeRoleData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    const data = { ...employeeRoleData };
    if (employeeRole) {
      await updateEmployeeRole(data);
    } else {
      await createEmployeeRole(data);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {employeeRole ? "Edit" : "Create"} Employee Role
        </ModalHeader>
        <ModalBody>
          {isLoadingCreateEmployeeRole || isLoadingUpdateEmployeeRole ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <Label for="name">
                  <span>Name</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={employeeRoleData.name || ""}
                  onChange={(e) =>
                    setEmployeeRoleData({
                      ...employeeRoleData,
                      name: e.target.value.trim(),
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer rounded border-0"
                  onClick={() =>
                    setEmployeeRoleData({
                      ...employeeRoleData,
                      isBillable: !employeeRoleData.isBillable,
                    })
                  }
                >
                  <div className="flex-shrink-0">Billable</div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={employeeRoleData.isBillable} />
                  </div>
                </ListGroupItem>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color="secondary" onClick={onClose} className="text-dark">
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EmployeeRoleModal;
