import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = {
  userData: null,
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={stateAndDispatch}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider };
