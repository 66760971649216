import React from "react";

import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";

const Page500 = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();

  return (
    <div className="text-center h-100 d-flex flex-column align-items-center mt-5">
      <h1 className="display-1 font-weight-bold">500</h1>
      <p className="h1">Internal server error.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        The server encountered something unexpected that didn't allow it to
        complete the request.
      </p>
      <Button
        onClick={() =>
          authContext.userData ? navigate("/users") : navigate("/auth/sign-in")
        }
        color="primary"
        size="lg"
      >
        Return to website
      </Button>
    </div>
  );
};

export default Page500;
