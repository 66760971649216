import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  useUpdateProject,
  useCreateProject,
} from "../../api/projects/Projects.hooks";

import { sharedHelper } from "../../helpers/sharedHelper";
import Loader from "../Loader";
import CustomCheckbox from "../CustomCheckbox";
import { useGetEmployeeRoles } from "../../api/employees/EmployeeRoles.hooks";

const ProjectModal = ({ project = { isActive: true }, onSubmit, onClose }) => {
  const [projectData, setProjectData] = useState(project);
  const [rates, setRates] = useState(
    project.rates?.reduce((p, c) => {
      p[c.employeeRoleId] = c.rate;
      return p;
    }, {}) || {}
  );
  const [retainers, setRetainers] = useState(
    project.retainers?.reduce((p, c) => {
      p[c.employeeRoleId] = c.hours;
      return p;
    }, {}) || {}
  );

  const {
    data: employeeRoles,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  useEffect(() => {
    getEmployeeRoles({
      isBillable: true,
    });
  }, [getEmployeeRoles]);

  const {
    isLoading: isLoadingUpdatingProject,
    update: updateProject,
    data: updateProjectData,
  } = useUpdateProject(project.id);

  const {
    isLoading: isLoadingCreateProject,
    mutate: createProject,
    data: createProjectData,
  } = useCreateProject();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (project.id) {
      await updateProject({
        ...projectData,
        rates,
        retainers,
      });
    } else {
      await createProject({
        ...projectData,
        rates,
        retainers,
      });
    }
  };

  useEffect(() => {
    if (updateProjectData) {
      sharedHelper.successToast(`Project saved`);
      onSubmit();
    }
  }, [updateProjectData, onSubmit]);

  useEffect(() => {
    if (createProjectData) {
      sharedHelper.successToast(`Project created`);
      onSubmit();
    }
  }, [createProjectData, onSubmit]);

  useEffect(() => {
    if (updateProjectData) {
      sharedHelper.successToast(`Project saved`);
      onSubmit();
    }
  }, [updateProjectData, onSubmit]);

  useEffect(() => {
    if (createProjectData) {
      sharedHelper.successToast(`Project created`);
      onSubmit();
    }
  }, [createProjectData, onSubmit]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="sm">
      <ModalHeader className="d-flex justify-content-between" close={closeBtn}>
        {project.id ? "Edit" : "Create"} Project
      </ModalHeader>
      <ModalBody>
        <Row>
          {isLoadingUpdatingProject ||
          isLoadingCreateProject ||
          isLoadingEmployeeRoles ? (
            <Loader size="sm" />
          ) : (
            <Col sm="12">
              <FormGroup>
                <Label for="name">
                  <span>Name</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="name"
                  placeholder="Enter the name.."
                  value={projectData.name || ""}
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      name: e.target.value.trim(),
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="overageHourIncrease">
                  <span>Overage Hour Increase Rate</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  type="text"
                  name="overageHourIncrease"
                  placeholder="Enter the increase.."
                  value={projectData.overageHourIncrease || ""}
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      overageHourIncrease: e.target.value.trim(),
                    })
                  }
                  required
                />
              </FormGroup>
              {project.id ? (
                <>
                  <ListGroup className="mt-4">
                    <ListGroupItem
                      className="d-flex justify-content-center font-weight-bold align-items-center py-2 bg-lighter"
                      tag="div"
                    >
                      Retainer Hours
                    </ListGroupItem>
                    {employeeRoles?.data ? (
                      employeeRoles?.data.length ? (
                        employeeRoles?.data.map((role) => (
                          <ListGroupItem
                            className="d-flex justify-content-between align-items-center py-2"
                            tag="div"
                          >
                            <span>{role.name}</span>
                            <div>
                              <input
                                min={0}
                                max={1000}
                                className="form-control-redesign text-right"
                                type="number"
                                name={role.name}
                                placeholder="0"
                                value={retainers[role.id] || ""}
                                onChange={(e) =>
                                  setRetainers({
                                    ...retainers,
                                    [role.id]: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                          </ListGroupItem>
                        ))
                      ) : (
                        <ListGroupItem
                          className="d-flex justify-content-center align-items-center py-2"
                          tag="div"
                        >
                          <span>No billable roles</span>
                        </ListGroupItem>
                      )
                    ) : (
                      <Loader size="sm" />
                    )}
                  </ListGroup>
                  <ListGroup className="mt-4">
                    <ListGroupItem
                      className="d-flex justify-content-center font-weight-bold align-items-center py-2 bg-lighter"
                      tag="div"
                    >
                      Rates
                    </ListGroupItem>
                    {employeeRoles?.data ? (
                      employeeRoles?.data.length ? (
                        employeeRoles?.data.map((role) => (
                          <ListGroupItem
                            className="d-flex justify-content-between align-items-center py-2"
                            tag="div"
                          >
                            <span>{role.name}</span>
                            <div>
                              <input
                                min={0}
                                max={1000}
                                className="form-control-redesign text-right"
                                type="number"
                                name={role.name}
                                placeholder="0"
                                value={rates[role.id] || ""}
                                onChange={(e) =>
                                  setRates({
                                    ...rates,
                                    [role.id]: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                          </ListGroupItem>
                        ))
                      ) : (
                        <ListGroupItem
                          className="d-flex justify-content-center align-items-center py-2"
                          tag="div"
                        >
                          <span>No billable roles</span>
                        </ListGroupItem>
                      )
                    ) : (
                      <Loader size="sm" />
                    )}
                  </ListGroup>
                  <ListGroup className="mt-4">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center cursor-pointer border-0"
                      onClick={() =>
                        setProjectData({
                          ...projectData,
                          isActive: !projectData.isActive,
                        })
                      }
                    >
                      <div className="flex-shrink-0">Active</div>
                      <div className="min-width-50 d-flex justify-content-end">
                        <CustomCheckbox checked={projectData.isActive} />
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </>
              ) : null}
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color="secondary" onClick={onClose} className="text-dark">
              Cancel
            </Button>
            <Button color="primary" onClick={doSubmit}>
              Save
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default ProjectModal;
