import React from "react";

import placeholder from "../../assets/img/placeholder.png";
import { useAuth } from "../../providers/authProvider";

const SideBarUser = () => {
  const [authContext] = useAuth();

  return (
    <div className="mb-2 sidebar-user px-3 py-2  d-flex align-items-center justify-content-center">
      <div
        className="rounded-circle img-thumbnail d-flex align-items-center justify-content-center"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${
            authContext.userData?.avatar || placeholder
          })`,
          width: "40px",
          height: "40px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="ml-3 d-flex flex-column align-items-start">
        <span className="text-muted font-500">
          {authContext.userData?.name}
        </span>
        <small className="text-muted">{authContext.userData?.role.name}</small>
      </div>
    </div>
  );
};

export default SideBarUser;
