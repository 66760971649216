import moment from "moment";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

import { toastConfig } from "../api/Api.hooks";

const ENV_BETA = "beta";
const ENV_PROD = "production";

export const sharedHelper = {
  determineArticle: (word) => {
    const lowerCaseWord = word.toLowerCase();
    const wordsStartingWithVowelSound = ["a", "e", "i", "o", "u"];
    if (wordsStartingWithVowelSound.includes(lowerCaseWord.charAt(0))) {
      return "an";
    } else {
      return "a";
    }
  },
  dismissToast: (toastId) => toast.dismiss(toastId),
  loadingToast: (msg) => {
    return toast.loading(msg, {
      toastId: "loading-toast",
      position: "bottom-right",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "light",
    });
  },
  warningToast: (msg) => {
    return toast.warn(msg, {
      ...toastConfig,
      toastId: "warning-toast",
    });
  },
  successToast: (msg) => {
    return toast.success(msg, {
      ...toastConfig,
      toastId: "success-toast",
    });
  },
  errorToast: (error) => {
    const genericError = "There was an error with your request.";
    const err = error.response?.data[0]?.msg || genericError;
    return toast.error(err, {
      ...toastConfig,
      toastId: "error-toast",
    });
  },
  isProdOrBeta: () =>
    process.env.REACT_APP_ENV === ENV_BETA ||
    process.env.REACT_APP_ENV === ENV_PROD,
  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  formatDateTime: (date, format = "MM/DD/YYYY, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  timeFromSeconds: (secs, separator) => {
    const pad = (num) => ("0" + num).slice(-2);
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    secs = secs % 60;
    return separator
      ? `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
      : `${hours}h ${pad(minutes)}min`;
  },
  formatTime: (seconds) => {
    const minutes = Math.floor(parseInt(seconds) / 60);
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;
    return `${hh < 10 ? `0${hh}` : hh}h ${mm < 10 ? `0${mm}` : mm}m`;
  },
  downloadFile: async (url, imageName = "image.png") => {
    const secureURL = url.replace("http://", "https://");
    FileSaver.saveAs(secureURL, imageName);
  },
};
