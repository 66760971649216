import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  InputGroup,
  Button,
} from "reactstrap";

import { faExchange, faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectModal from "../components/projects/ProjectModal";

import { DebounceInput } from "react-debounce-input";
import { sharedHelper } from "../helpers/sharedHelper";
import AdvanceTableWrapper from "../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../components/advanceTable/AdvanceTablePagination";
import ConfirmationModal from "../components/ConfirmationModal";
import {
  useDeleteProject,
  useGetProjects,
  useSyncProjects,
} from "../api/projects/Projects.hooks";

const columns = (onEdit, onDelete) => [
  {
    accessor: "name",
    Header: "Name",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name, avatar } = rowData.row.original;
      return (
        <div className="d-flex align-items-center">
          <img
            alt={name}
            className="img rounded-circle"
            src={avatar.replace("size=small", "")}
            style={{ maxWidth: 50 }}
          />
          <span className="ml-2">{name}</span>
        </div>
      );
    },
  },
  {
    accessor: "id",
    Header: "",
    disableSortBy: true,
    cellProps: {
      className: "text-right",
    },
    Cell: (rowData) => (
      <div>
        <Button
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row.original)}
        >
          Edit
        </Button>
        <Button
          color="none"
          className="text-danger"
          onClick={() => onDelete(rowData.row.original)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const Projects = () => {
  const [projects, setProjects] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [deleteProjectId, setProjectDeleteId] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    get: getProjects,
  } = useGetProjects();

  const {
    data: syncProjectsData,
    isLoading: isLoadingSyncProjects,
    get: syncProjects,
  } = useSyncProjects();

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData);
    }
  }, [projectsData, setProjects]);

  const { data: deleteProjectData, deleteProject } =
    useDeleteProject(deleteProjectId);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const localSortBy = data.id;
        const localDirection = data.desc ? "desc" : "asc";
        if (localSortBy.id === sortBy && localDirection === direction) {
          return;
        }
        setSortBy(localSortBy);
        setDirection(localDirection);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getProjects({
      search: search,
      page: page - 1,
      pageSize: sizePerPage,
      sortBy: sortBy,
      direction: direction,
    });
  }, [getProjects, sortBy, direction, sizePerPage, page, search, refresh]);

  useEffect(() => {
    if (deleteProjectId) {
      deleteProject();
    }
  }, [deleteProject, deleteProjectId]);

  useEffect(() => {
    if (deleteProjectData) {
      sharedHelper.successToast(`Project deleted`);
      setProjectDeleteId();
      setRefresh((refresh) => !refresh);
    }
  }, [deleteProjectData, setRefresh]);

  useEffect(() => {
    if (syncProjectsData) {
      sharedHelper.successToast(`Projects synced`);
      setRefresh((refresh) => !refresh);
    }
  }, [syncProjectsData, setRefresh]);

  const onEdit = (project) => setEditModal(project);

  const onDelete = (project) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        setProjectDeleteId(project.id);
      },
      onClose: () => {
        setConfirmationModal();
        setProjectDeleteId();
        setRefresh(!refresh);
      },
      title: "Delete Project",
      body: `Are you sure you want to delete ${project.name}?`,
      confirmColor: "danger",
    });
  };

  return (
    <Container fluid>
      <div className="w-100">
        <AdvanceTableWrapper
          exportable
          exportName="Projects.csv"
          columns={columns(onEdit, onDelete)}
          data={projects.data || []}
          pageSize={sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: sortBy,
            direction: direction,
          }}
        >
          <Card className="mb-3 w-100 box-shadow-none">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h2 className="mb-0 ">Projects</h2>
                <small className="text-muted ml-2 pt-1">
                  ({projects.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <DebounceInput
                    className="search border-0 form-control"
                    maxLength={50}
                    minLength={1}
                    debounceTimeout={800}
                    placeholder="Search for.."
                    value={search}
                    onChange={(evt) => {
                      setSearch(evt.target.value);
                      setPage(INIT_PAGE);
                    }}
                  />
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  onClick={() => syncProjects()}
                >
                  <FontAwesomeIcon icon={faExchange} />
                </Button>
                <div id="table-export" />
                <Button color="primary" onClick={() => setCreateModal(true)}>
                  Create
                </Button>
              </div>
            </CardHeader>
            <CardBody className="p-4">
              <AdvanceTable
                table
                isLoading={isLoadingProjects || isLoadingSyncProjects}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0 overflow-hidden",
                }}
              />

              <AdvanceTablePagination
                totalCount={projects.count}
                pageCount={projects.totalPages}
                currentPage={page - 1}
                onPageChange={(page) => setPage(page)}
                pageSize={sizePerPage}
                onPageSizeChange={(sizePerPage) => {
                  setSizePerPage(sizePerPage);
                  setPage(INIT_PAGE);
                }}
              />
            </CardBody>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {editModal ? (
        <ProjectModal
          project={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh(!refresh);
          }}
        />
      ) : createModal ? (
        <ProjectModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh(!refresh);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Projects;
