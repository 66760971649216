import React, { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";

import { Route, Outlet } from "react-router-dom";

import { Navigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { authApi } from "./api/authServices";
import { AuthProvider, useAuth } from "./providers/authProvider";
import { SidebarProvider } from "./providers/sidebarProvider";
import { BaseRoutes } from "./routes/Routes";

import AdminLayout from "./layouts/Admin";
import ManagerLayout from "./layouts/Manager";

import Page401 from "./pages/auth/Page401";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Loader from "./components/Loader";
import Users from "./pages/Users";
import Employees from "./pages/Employees";
import Projects from "./pages/Projects";
import WorkPerformedByProject from "./pages/reports/WorkPerformedByProject";
import EmployeeRoles from "./pages/EmployeeRoles";
import WorkPerformedByEmployee from "./pages/reports/WorkPerformedByEmployee";

const RoutesWrapper = () => {
  const [authContext, setAuthContext] = useAuth();

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await authApi.validate();
        if (!userData) {
          setLoading(false);
          setRedirectToLogin(true);
        } else {
          setAuthContext({ userData });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setRedirectToLogin(true);
      }
    };
    if (!authContext.userData) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [authContext.userData, setAuthContext]);

  return loading ? (
    <Loader />
  ) : redirectToLogin ? (
    <Navigate to={`/auth/sign-in`} />
  ) : (
    <Outlet />
  );
};

const App = () => (
  <AuthProvider>
    <SidebarProvider>
      <ToastContainer />
      <BaseRoutes>
        <Route path={"/"} element={<RoutesWrapper />}>
          <Route path={"/"} element={<AdminLayout />}>
            <Route path={"/users"} element={<Users />} />
            <Route path={"/employees"} element={<Employees />} />
            <Route path={"/employee-roles"} element={<EmployeeRoles />} />
            <Route path={"/projects"} element={<Projects />} />
          </Route>
          <Route path={"/"} element={<ManagerLayout />}>
            <Route path={"/reports"}>
              <Route
                path={"work-performed-by-project"}
                element={<WorkPerformedByProject />}
              />
              <Route
                path={"work-performed-by-employee"}
                element={<WorkPerformedByEmployee />}
              />
            </Route>
            <Route path={"*"} element={<Page404 />} />
            <Route path={"/error/500"} element={<Page500 />} />
            <Route path={"/error/404"} element={<Page404 />} />
            <Route path={"/error/401"} element={<Page401 />} />
          </Route>
        </Route>
      </BaseRoutes>
    </SidebarProvider>
  </AuthProvider>
);

export default App;
