import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";

import SignIn from "../pages/auth/signIn/SignIn";
import AuthLayout from "../layouts/Auth";

const BaseRoutes = ({ children }) => (
  <Router basename={"/"}>
    <Routes>
      <Route
        path={"/auth/sign-in"}
        element={
          <AuthLayout>
            <SignIn />
          </AuthLayout>
        }
      />
      <Route path={""} element={<Navigate to="/users" />} />
      {children}
    </Routes>
  </Router>
);

export { BaseRoutes };
