import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { Badge } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarItem = ({
  name,
  badgeColor,
  badgeText,
  icon,
  path,
  action,
  className = "",
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === path;

  return (
    <div
      id={name}
      className={`sidebar-item ${isActive ? "active" : ""} ${className}`}
      onClick={() => (action ? action() : navigate(path))}
    >
      <NavLink
        to="#"
        className={({ isActive }) =>
          `sidebar-link text-truncate ${
            isActive ? "active-style" : "none"
          } py-2`
        }
      >
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon
              color={isActive ? "primary" : "gray-600"}
              icon={icon}
              fixedWidth
              className={`align-middle mx-2 sidebar-icon ${
                isActive ? "text-primary" : "text-muted"
              }`}
            />
            <span
              className={`align-middle text-truncate ml-1 ${
                isActive ? "text-primary" : ""
              }`}
            >
              {name}
            </span>
          </React.Fragment>
        ) : (
          name
        )}
        {badgeColor && badgeText !== undefined ? (
          <Badge
            style={{ paddingTop: "4px" }}
            color={badgeColor}
            size={18}
            className="sidebar-badge"
          >
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </div>
  );
};

export default SidebarItem;
