import { useCallback } from "react";
import { useApiGet, useApiMutation } from "../Api.hooks";
import { HttpMethod } from "../Api";

const endpoint = `/api/employees`;

export const useGetEmployees = () => {
  return useApiGet(endpoint);
};

export const useSyncEmployees = () => {
  return useApiGet(`${endpoint}/sync-with-jira`);
};

export const useCreateEmployee = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateEmployee = (employeeId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${employeeId}`
  );

  const update = useCallback(
    async (employee) => {
      await mutate(employee);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteEmployee = (employeeId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${employeeId}`
  );

  const deleteEmployee = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteEmployee };
};
