import React from "react";
import { useSidebar, ACTIONS } from "../providers/sidebarProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Hamburger = () => {
  const [, setSidebarContext] = useSidebar();

  return (
    <span
      className="sidebar-toggle d-flex d-block d-lg-none mt-3 ml-4 pl-1"
      onClick={() => setSidebarContext({ action: ACTIONS.TOGGLE })}
    >
      <FontAwesomeIcon icon={faBars} size="lg" />
    </span>
  );
};

export default Hamburger;
