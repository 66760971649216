import { useApiGet } from "../Api.hooks";

const endpoint = `/api/reports`;

export const useGetWorkPerformedByProject = () => {
  return useApiGet(`${endpoint}/work-performed-by-project`);
};

export const useGetWorkPerformedByEmployee = () => {
  return useApiGet(`${endpoint}/work-performed-by-employee`);
};
