import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  InputGroup,
  Button,
} from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmployeeRoleModal from "../components/employees/EmployeeRoleModal";

import { DebounceInput } from "react-debounce-input";
import { sharedHelper } from "../helpers/sharedHelper";
import AdvanceTableWrapper from "../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../components/advanceTable/AdvanceTablePagination";
import ConfirmationModal from "../components/ConfirmationModal";
import {
  useDeleteEmployeeRole,
  useGetEmployeeRoles,
} from "../api/employees/EmployeeRoles.hooks";

const columns = (onEdit, onDelete) => [
  {
    accessor: "name",
    Header: "Name",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name } = rowData.row.original;
      return name || "-";
    },
  },
  {
    accessor: "isBillable",
    Header: "Billable",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { isBillable } = rowData.row.original;
      return isBillable ? "Yes" : "No";
    },
  },
  {
    accessor: "id",
    Header: "",
    disableSortBy: true,
    cellProps: {
      className: "text-right",
    },
    Cell: (rowData) => (
      <div>
        <Button
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row.original)}
        >
          Edit
        </Button>
        <Button
          color="none"
          className="text-danger"
          onClick={() => onDelete(rowData.row.original)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const EmployeeRoles = () => {
  const [employeeRoles, setEmployeeRoles] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [deleteEmployeeRoleId, setEmployeeRoleDeleteId] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: employeeRolesData,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  const { data: deleteEmployeeRoleData, deleteEmployeeRole } =
    useDeleteEmployeeRole(deleteEmployeeRoleId);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const localSortBy = data.id;
        const localDirection = data.desc ? "desc" : "asc";
        if (localSortBy.id === sortBy && localDirection === direction) {
          return;
        }
        setSortBy(localSortBy);
        setDirection(localDirection);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getEmployeeRoles({
      search: search,
      page: page - 1,
      pageSize: sizePerPage,
      sortBy: sortBy,
      direction: direction,
    });
  }, [getEmployeeRoles, sortBy, direction, sizePerPage, page, search, refresh]);

  useEffect(() => {
    if (employeeRolesData) {
      setEmployeeRoles(employeeRolesData);
    }
  }, [employeeRolesData, setEmployeeRoles]);

  useEffect(() => {
    if (deleteEmployeeRoleId) {
      deleteEmployeeRole();
    }
  }, [deleteEmployeeRole, deleteEmployeeRoleId]);

  useEffect(() => {
    if (deleteEmployeeRoleData) {
      sharedHelper.successToast(`Employee role deleted`);
      setEmployeeRoleDeleteId();
      setRefresh((refresh) => !refresh);
    }
  }, [deleteEmployeeRoleData, setRefresh]);

  const onEdit = (employeeRole) => setEditModal(employeeRole);

  const onDelete = (employeeRole) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        setEmployeeRoleDeleteId(employeeRole.id);
      },
      onClose: () => {
        setConfirmationModal();
        setEmployeeRoleDeleteId();
        setRefresh(!refresh);
      },
      title: "Delete employee eole",
      body: `Are you sure you want to delete ${employeeRole.name}?`,
      confirmColor: "danger",
    });
  };

  return (
    <Container fluid>
      <div className="w-100">
        <AdvanceTableWrapper
          exportable
          exportName="EmployeeRoles.csv"
          columns={columns(onEdit, onDelete)}
          data={employeeRoles.data || []}
          pageSize={sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: sortBy,
            direction: direction,
          }}
        >
          <Card className="mb-3 w-100 box-shadow-none">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h2 className="mb-0 ">Employee Roles</h2>
                <small className="text-muted ml-2 pt-1">
                  ({employeeRoles.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <DebounceInput
                    className="search border-0 form-control"
                    maxLength={50}
                    minLength={1}
                    debounceTimeout={800}
                    placeholder="Search for.."
                    value={search}
                    onChange={(evt) => {
                      setSearch(evt.target.value);
                      setPage(INIT_PAGE);
                    }}
                  />
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
                <div id="table-export" />
                <Button color="primary" onClick={() => setCreateModal(true)}>
                  Create
                </Button>
              </div>
            </CardHeader>
            <CardBody className="p-4">
              <AdvanceTable
                table
                isLoading={isLoadingEmployeeRoles}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0 overflow-hidden",
                }}
              />
              <AdvanceTablePagination
                totalCount={employeeRoles.count}
                pageCount={employeeRoles.totalPages}
                currentPage={page - 1}
                onPageChange={(page) => setPage(page)}
                pageSize={sizePerPage}
                onPageSizeChange={(sizePerPage) => {
                  setSizePerPage(sizePerPage);
                  setPage(INIT_PAGE);
                }}
              />
            </CardBody>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {editModal ? (
        <EmployeeRoleModal
          employeeRole={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh(!refresh);
          }}
        />
      ) : createModal ? (
        <EmployeeRoleModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh(!refresh);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default EmployeeRoles;
