import React, { useEffect, useState } from "react";

import { Badge, Collapse } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import SidebarItem from "./SidebarItem";

const SidebarCategory = ({
  name,
  icon,
  entries,
  path,
  badgeColor,
  badgeText,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location.pathname.includes(path));

  useEffect(() => {
    const isOpen = location.pathname.includes(path);
    setIsOpen(isOpen);
  }, [location.pathname, path]);

  const filterEntries = (entries) => entries.filter((entry) => entry.sidebar);

  const items =
    entries.length !== undefined
      ? filterEntries(entries).map((entry, index) => {
          const entryPath = entry.package
            ? `${entry.package}/${path}/${entry.path}`
            : `${path}/${entry.path}`;
          return (
            <SidebarItem
              key={index}
              icon={entry.icon}
              name={entry.name}
              path={entryPath}
              action={entry.action}
            />
          );
        })
      : Object.keys(entries).map((category, i) => (
          <div key={i}>
            <div className="sidebar-header">{category}</div>
            {filterEntries(entries[category]).map((entry, index) => {
              const entryPath = entry.package
                ? `${entry.package}/${path}/${entry.path}`
                : `${path}/${entry.path}`;
              return (
                <SidebarItem
                  key={index}
                  icon={entry.icon}
                  name={entry.name}
                  path={entryPath}
                  action={entry.action}
                />
              );
            })}
          </div>
        ));

  return (
    <div className={`${isOpen ? "active" : ""} sidebar-category mb-1`}>
      <div
        data-toggle="collapse"
        className={
          "sidebar-link d-flex align-items-center " +
          (!isOpen ? "collapsed" : "")
        }
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen ? "true" : "false"}
      >
        {icon.type === "img" ? (
          icon
        ) : (
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className={`align-middle mx-2 sidebar-icon ${
              isOpen ? "text-primary" : "text-muted"
            }`}
          />
        )}{" "}
        <span
          className={`align-middle text-truncate ml-1 ${
            isOpen ? "text-primary" : ""
          }`}
        >
          {name}
        </span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </div>
      {entries ? (
        <Collapse isOpen={Boolean(isOpen)}>
          <div className="sidebar-dropdown list-unstyled d-flex flex-column">
            {items}
          </div>
        </Collapse>
      ) : null}
    </div>
  );
};

export default SidebarCategory;
