import React from "react";
import { Table } from "reactstrap";
import Loader from "../Loader";

const AdvanceTable = ({
  isLoading,
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
}) => {
  return (
    <Table
      {...getTableProps(tableProps)}
      className="position-relative overflow-hidden"
    >
      <thead className={headerClassName}>
        <tr>
          {headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps(
                column.getSortByToggleProps(column.headerProps)
              )}
            >
              {column.render("Header")}
              {column.canSort ? (
                column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )
              ) : (
                ""
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={bodyClassName}>
        {isLoading ? (
          <tr>
            <td colSpan={headers.length}>
              <Loader size="sm" />
            </td>
          </tr>
        ) : page.length ? (
          page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr>
            <td
              className="text-muted small text-center"
              colSpan={headers.length}
            >
              No data to show
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default AdvanceTable;
